.modal-fullscreen .ant-modal-content {
    position: relative;
    width: 100vw;
    height: 100vh;
}

.modal-fullscreen .ant-modal-body {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    align-items: center;
}